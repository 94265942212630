import { __decorate, __extends } from "tslib";
import { Injectable } from '@angular/core';
import { AwesomeCordovaNativePlugin, cordova } from '@awesome-cordova-plugins/core';
import * as i0 from "@angular/core";
var Keychain = /** @class */function (_super) {
  __extends(Keychain, _super);
  function Keychain() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  Keychain.prototype.get = function (key, touchIDMessage) {
    return cordova(this, "get", {
      "callbackOrder": "reverse"
    }, arguments);
  };
  Keychain.prototype.set = function (key, value, useTouchID) {
    return cordova(this, "set", {
      "callbackOrder": "reverse"
    }, arguments);
  };
  Keychain.prototype.getJson = function (key, touchIDMessage) {
    return cordova(this, "getJson", {
      "callbackOrder": "reverse"
    }, arguments);
  };
  Keychain.prototype.setJson = function (key, obj, useTouchId) {
    return cordova(this, "setJson", {
      "callbackOrder": "reverse"
    }, arguments);
  };
  Keychain.prototype.remove = function (key) {
    return cordova(this, "remove", {
      "callbackOrder": "reverse"
    }, arguments);
  };
  Keychain.ɵfac = /* @__PURE__ */(() => {
    let ɵKeychain_BaseFactory;
    return function Keychain_Factory(t) {
      return (ɵKeychain_BaseFactory || (ɵKeychain_BaseFactory = i0.ɵɵgetInheritedFactory(Keychain)))(t || Keychain);
    };
  })();
  Keychain.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: Keychain,
    factory: Keychain.ɵfac
  });
  Keychain.pluginName = "Keychain";
  Keychain.plugin = "cordova-plugin-ios-keychain";
  Keychain.pluginRef = "window.Keychain";
  Keychain.repo = "https://github.com/ionic-team/cordova-plugin-ios-keychain";
  Keychain.platforms = ["iOS"];
  Keychain = __decorate([], Keychain);
  return Keychain;
}(AwesomeCordovaNativePlugin);
export { Keychain };
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && void 0;
})();
